:root{
  --main-font: 'Roboto';
  --alternate-font: sans-serif;
  --font-size: 14px;
  --line-height: 1.4;

  --dark: rgb(11, 87, 107);
  --middle: rgb(36, 152, 184);
  --light: rgb(73, 201, 236);
  --gray: rgb(99, 133, 143);
  --time-transition: .5s;

  --shadow: rgba(0, 0, 0, .2);
  --bg: rgb(249, 249, 249);
  --blank: rgb(255, 255, 255);
  --bg-alternate: #eee;
  --text-color: #333;
  --title-color: var(--second-color);

  --link-color: var(--main-color);
  --link-hover-color: var(--second-color);

  --container-width: 1200px;
  --header-height: 64px;
}