.Menu{
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5rem;
  ul{
    display: flex;
    justify-content: flex-end;
  }
  a{
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: var(--dark);
  }
  .User{
    display: block;
    padding: 0;
    height: 42px;
    width: 42px;
    img{
      height: 100%;
      border-radius: 50%;
    }
  }
  i{
    margin-right: 0.5rem;
    font-size: 1.5em;
    color: var(--main-color);
    width: 25px;
  }
}
@media screen and (min-width: 900px){
  .Menu{
    display: flex;
  }
}

.Panel-menu{
  padding: 2rem;
  p{
    text-align: left;
    margin-bottom: 0.25rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--dark);
  }
  ul{
    text-align: left;
    font-family: var(--main-font), sans-serif;
  }
  a{
    padding: 0.5em 0;
    display: flex;
    align-items: center;
    color: var(--dark);
    &[class*="Button"]{
      justify-content: center;
    }
  }
  .User{
    flex-direction: column;
    img{
      width: 50px;
      height: 50px;
      display: block;
      border-radius: 50%;
    }
    p{
      color: var(--main-color);
      margin-top: 5px;
      text-transform: none;
    }
    button{
      cursor: pointer;
    }
  }
  form{
    text-align: center;
    button{
      padding: .5rem 1rem;
    }
  }
  i{
    margin-right: 1rem;
    font-size: 1.5em;
    color: var(--main-color);
    width: 25px;
  }
}

.Footer-menu{
  ul{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
    grid-gap: 1rem;
  }
  a{
  }
}

@media screen and (min-width: 500px){
  .Footer-menu{
    ul{
      grid-template-columns: repeat(2, 200px);
      grid-template-rows: auto;
      grid-auto-rows: auto;
      grid-auto-flow: row;
      justify-items: start;
    }
  }
}
@media screen and (min-width: 720px){
  .Footer-menu{
    nav{
      width: 50%;
    }
  }
}
@media screen and (min-width: 1024px){
  .Footer-menu{
    ul{
      grid-template-columns: repeat(3, 200px);
    }
  }
}