.Header{
  background-color: white;
  height: var(--header-height);
  overflow: hidden;
  border-top: 5px solid var(--dark);
  &-container{
    width: 100%;
    height: 100%;
    padding: 7px 20px;
  }
  &-content{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  h2{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}