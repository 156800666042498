.Button{
  border: 0;
  outline: none;
  &--simple{
    background-color: transparent;
    color: var(--dark);
    font-size: 2em;
  }
  &-main{
    &.Button-white{
      background-color: white;
    }
    background-color: transparent;
    color: var(--dark);
    text-align: center;
  }
}
.Button--simple.Button--dark{
  border-radius: 2px;
  padding: 15px 30px;
  display: inline-block;
  border: 2px solid var(--dark);
  color: var(--dark);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  &:hover{
    background-color: var(--dark);
    color: white;
  }
}
.Button-cta{
    &.Button-main{
      background-color: var(--dark);
      color: white;
      border-radius: 15px 15px;
    }
}