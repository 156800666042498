.Advertising{
  padding-top: 1rem;
  border-top: 1px solid rgb(211, 211, 211);
  p{
    padding-bottom: .5rem;
  }
  &-links{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
  }
}
@media screen and (min-width:720px){
  .Advertising{
    padding-top: 2rem;
    &-links{
      grid-template-columns: 1fr;
    }
  }
}
@media screen and (min-width:1024px){
  .Advertising{
    &-links{
      grid-template-columns: 1f;
    }
  }
}