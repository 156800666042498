.Tags{
  display: flex;
  flex-wrap: wrap;
  li{
    padding: 10px 12px;
    background-color: var(--dark);
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-weight: 300;
    color: white;
    transition: background-color var(--time-transition);
    &:hover{
      background-color: var(--middle);
    }
  }
  li::before{
    content: "";
    display: inline-block;
    background-color: white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }
  li.type{
    background-color: var(--dark);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 7px;
    transition: background-color var(--time-transition);
    i{
      margin-right: 5px;
    }
    &:hover{
      background-color: var(--middle);
    }
  }
  li.type::before{
    display: none;
  }
}
.Tags--simple{
  display: flex;
  flex-wrap: wrap;
  li{
    padding: 10px 12px;
    //background-color: var(--dark);
    border: 1px solid var(--dark);
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    font-weight: 300;
    color: var(--dark);
    transition: border var(--time-transition);
    &:hover{
      //background-color: var(--middle);
      border: 1px solid var(--middle);
      color: var(--middle);
      &::before{
        border: 1px solid var(--middle);
      }
    }
  }
  li::before{
    content: "";
    display: inline-block;
    //background-color: white;
    border: 1px solid var(--dark);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 7px;
  }
  li.type{
    background-color: var(--dark);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 7px;
    transition: background-color var(--time-transition);
    i{
      margin-right: 5px;
    }
    &:hover{
      background-color: var(--middle);
    }
  }
  li.type::before{
    display: none;
  }
}
.Tags--inline{
  color: var(--dark);
  a{
    color: var(--dark);
    display: inline-block;
  }
}