.Footer{
  border-top: 5px solid var(--dark);
  border-bottom: 10px solid var(--dark);
  position: relative;
  background-color: var(--bg-color);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: row;
  grid-gap: 0;
  nav{
    padding: 15px 20px;
  }
  a{
    color: var(--dark);
  }
  .Logo{
    padding: 15px 20px;
  }
  p{
    text-align: left;
    padding: 0 20px 15px;
  }
}