.SocialMedia{
  &-buttons{
    display: flex;
    flex-wrap: wrap;
  }
  &-button{
    margin-right: 10px;
    margin-bottom: 10px;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 50%;
    color: white;
    &.facebook{
      background-color: #3b5998;
    }
    &.twitter{
      background-color: #0084b4;
    }
    &.linkedin{
      background-color: #0077b5;
    }
    &.reddit{
      background-color: #FF4500;
    }
    &.whatsapp{
      background-color: #1EBEA5;
    }
  }
}
@media screen and (min-width:720px){
  .SocialMedia{
    p{
      display: block;
    }
  }
}