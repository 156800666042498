.Search{
  display: flex;
  border: 2px solid var(--middle);
  input[type="text"]{
    display: block;
    width: 100%;
    padding: 16px 20px;
    font-family: var(--main-font), var(--alternate-font);
    font-size: 14px;
    border: none;
    outline: none;
    background-color: white;
    color: var(--middle);
  }
  ::placeholder{
    color: var(--middle);
  }
  input[type="submit"], button{
    display: block;
    outline: none;
    padding: 16px 20px;
    background-color: white;
    color: var(--middle);
    font-family: var(--main-font), var(--alternate-font);
    font-size: 14px;
    border: none;
  }
}
.Contact{
  display: flex;
  border: 2px solid var(--middle);
  input[type="text"]{
    display: block;
    width: 100%;
    padding: 16px 20px;
    font-family: var(--main-font), var(--alternate-font);
    font-size: 14px;
    border: none;
    outline: none;
    background-color: white;
    color: var(--middle);
  }
  ::placeholder{
    color: var(--middle);
  }
  input[type="submit"], button{
    display: block;
    outline: none;
    padding: 16px 20px;
    background-color: white;
    color: var(--middle);
    font-family: var(--main-font), var(--alternate-font);
    font-size: 14px;
    border: none;
  }
}