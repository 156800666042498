::-webkit-scrollbar {
  width: 0.5rem;
}
/* Track */
::-webkit-scrollbar-track {
  background: #ffffff; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--middle);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}