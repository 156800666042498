html{
  box-sizing: border-box;
  font-family: var(--main-font), var(--alternate-font);
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--text-color);
}

*,
*:after,
*:before{
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body{
  overflow-x: hidden;
  background-color: var(--bg);
}
body.disabled{
  overflow: hidden;
}

a{
  text-decoration: none;
  color: white;
  transition: all .3s ease-out;
  display: block;
}

img{
  display: block;
  max-width: 100%;
  height: auto;
}

li{
  list-style-position: inside;
  list-style: none;
}

::selection{
  background-color: var(--dark);
  color: white;
}

h1{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 40px;
  line-height: 48px;
  font-weight: 900;
  text-transform: uppercase;
}
h2{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  text-transform: uppercase;
}
h3{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: uppercase;
}
/* h4{
  font-size: 1.142em;
  font-weight: bold;
}
h5{
  font-size: 0.9em;
}
h6{
  font-size: 0.8em;
} */
/* p{
  font-family: var(--second-font), sans-serif;
} */
/* small{
  font-family: var(--second-font), sans-serif;
} */

p.quote{
  font-family: Georgia, var(--alternate-font);
  font-size: 20px;
  line-height: 30px;
  font-style: italic;
}

p.large{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
}

p{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

p.small{
  font-family: var(--main-font), var(--alternate-font);
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
}

.Notice{
  padding: 1rem;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  i{
    font-size: 2rem;
    margin-right: 1rem;
  }
}
.Warning{
  padding: 1rem;
  background-color: #dc3545;
}
