.u-text-center{
  text-align: center;
}
.U-line{
  width: 100%;
  height: 1px;
  background-color: #c7c7c7;
}
.mt5{
  margin-top: 5px;
}