.Modal{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  background-color: transparent;
  transform: translate(-100%);
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform .25s .75s, background-color .75s;
  &.is-active{
    transition: transform .25s, background-color .75s .25s;
    transform: translate(0);
    background-color: rgba(0, 0, 0, .5);
  }
  &--dark{
    &.is-active{
      background-color: rgba(0, 0, 0, .9);
    }
  }
  &-body{
    background-color: white;
    padding: 1rem;
    width: 90%;
    max-width: 360px;
    border-radius: .2rem;
  }
  h3{
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 2px solid var(--dark);
    font-weight: 400;
  }
  form{
    margin-top: 2rem;
    text-align: center;
    input{
      border: 0;
      background-color: #eee;
      width: 100%;
      padding: 0.9rem;
      outline: none;
      margin-bottom: 1.14rem;
      border-radius: .2rem;
      font-family: var(--main-font), var(--alternate-font);
    }
    button{
      padding: 0.75em 0.9em;
      border: 2px solid var(--dark);
      outline: none;
      cursor: pointer;
      background-color: transparent;
    }
    p{
      color: red;
      text-align: left;
    }
  }
  .Info{
    margin-top: 2rem;
    text-align: center;
    h6{
      font-weight: 700;
    }
    a{
      text-decoration: underline;
      color: var(--dark);
    }
  }
  .Modal-footer{
    margin-top: 2rem;
    text-align: center;
    h5{
      font-weight: 700;
    }
    a{
      display: inline;
      text-decoration: underline;
      color: var(--dark);
    }
  }
  p{
    color: white;
    margin-top: 5px;
  }
}
@media screen and (min-width: 400px){
}