.Panel{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translate(100%, 0);
  transition: transform .3s .15s, background-color .15s;
  text-align: right;
  &.is-active{
    transition: transform .3s, background-color .15s .3s;
    background-color: rgba(0, 0, 0, .5);
    transform: translate(0, 0);
  }
  &-btnInner{
    width: 100%;
    padding: 1rem;
    height: var(--header-height);
    text-align: right;
  }
  &-container{
    overflow: auto;
    height: 100%;
    display: inline-block;
    background-color: white;
    &>*{
      border-bottom: 1px solid var(--middle);
    }
  }
}